.hello
    padding 0 0 25px
    &__inner
        position relative

.slider
    position relative
    &__item
        position relative
        width 100%
        height 570px
        display: flex
        align-items center
        @media(max-width 1330px)
            justify-content center
        @media(max-width 480px)
            height 400px
    &__content
        margin 0 70px
        @media(max-width 1330px)
            margin 0 25px
    .owl-dots
        position: absolute
        top 0
        right -50px
        width 35px
        height 100%
        display: flex
        flex-direction: column
        justify-content center
        @media(max-width 768px)
            top auto
            bottom 20px
            left 0
            right auto
            width 100%
            height 15px
            flex-direction: row
            align-items center
        .owl-dot
            size(30px, 4px)
            background-color: #125c89;
            margin-bottom 20px
            transition: .3s
            flex-shrink: 0
            @media(max-width 768px)
                size(10px)
                border-radius: 50%
                margin-right 5px
                margin-bottom 0
            &:last-child
                margin-bottom 0
                @media(max-width 768px)
                    margin-right 0
            &.active
                width 35px
                background-color: #143261;
                @media(max-width 768px)
                    size(15px)
.helloImg
    position: absolute
    size(100%)
    object-fit: cover
    z-index -1

.helloMouse
    position: absolute
    top calc(50% - 15.5px)
    left -50px
    width: 20px!important
    height 31px!important
    object-fit: contain
    @media(max-width 768px)
        display: none

.helloContent
    position relative
    background-color  rgba(255, 255, 255, 0.95)
    border 15px solid #fff
    padding 30px 23px
    display: flex
    flex-direction: column
    align-items center
    @media(max-width 992px)
        padding-left 25px
        padding-right 25px
    &__title
        font-family: 'Proxima Nova Bl';
        text-transform: uppercase;
        text-align center
        font-size: 36px;
        color: #252525;
        line-height 1
        @media(max-width 480px)
            font-size 24px
    &__text
        text-transform: uppercase;
        text-align center
        font-size: 20px;
        color: #252525;
        @media(max-width 480px)
            font-size 16px
    &__button
        position: absolute
        bottom: -35px
        font-size 16px
        padding-left 60px
        padding-right 60px
        @media(max-width 480px)
            padding-left 10px
            padding-right 10px

// -------------------------------------
.offer
    padding 55px 0
    &__inner
        position relative
        background-color #fff
        padding 30px 100px 25px
        display: flex
        flex-direction: column
        align-items flex-end
        overflow hidden
        @media(max-width 1480px)
            padding-left 50px
            padding-right 50px
        @media(max-width 1330px)
            padding: 15px 15px 45px
            flex-direction: row
            flex-wrap: wrap
            align-items center

.offerImg
    position: absolute
    left: 65px;
    bottom: -40px;
    z-index 0
    @media(max-width 1330px)
        position: static
        width 50%
    @media(max-width 768px)
        width 100%
        margin-bottom 25px


.offerGrid
    max-width 903px
    display: flex
    flex-wrap: wrap
    justify-content flex-end
    margin-left -15px
    margin-right -15px
    z-index 1
    @media(max-width 1330px)
        max-width 50%
        justify-content: flex-start
    @media(max-width 768px)
        max-width 100%
    &__col
        width 33.33%
        padding 0 15px
        margin-bottom 13px
        @media(max-width 1330px)
            width 100%
            margin-bottom 25px

.offerBox
    display: flex
    align-items center
    &__icon
        size(43px)
        object-fit: contain
        margin-right 20px
        flex-shrink: 0
    &__text
        font-size: 16px;
        color: #252525;

.offerForm
    max-width 640px
    border-top: 2px solid #f5efe3;
    padding-top 17px
    z-index 1
    @media(max-width 1330px)
        max-width 100%
        padding 30px 100px 0
    @media(max-width 992px)
        padding-left 0
        padding-right 0
    &__title
        font-size: 30px;
        color: #252525;
        text-transform uppercase
        font-family: 'Proxima Nova Bl'
        text-align center
        line-height 1
        margin-bottom 9px
        @media(max-width 576px)
            font-size 24px
    &__wrapper
        max-width 322px
        margin 0 auto
    &__input
        border none
        border-bottom: 1px solid #000
        height 36px
        width 100%
        text-align center
        font-size 14px
        color #000
        padding-left 15px
        padding-right 15px
        background-color transparent
        margin-bottom 19px
    &__button
        width: 100%
        margin-top 17px
        margin-bottom 15px


// -------------------------------------
.services
    padding 23px 0
    &__inner
        position relative
        padding 71px 240px 71px 78px
        @media(max-width 1330px)
            padding: 70px 30px
        @media(max-width 576px)
            padding 50px 10px
    &__img
        position: absolute
        top 0
        left 0
        size(100%)
        object-fit: cover
        z-index -1

.servicesContent
    border: 20px solid #fff
    display: flex
    justify-content flex-start
    @media(max-width 576px)
        border: 10px solid #fff
    &__wrapper
        background-color rgba(255, 255, 255, 0.9)
        padding 21px 13.5px 9px
        @media(max-width 576px)
            padding: 20px 10px
    &__title
        font-family: 'Proxima Nova Bl';
        font-size: 30px;
        font-weight bold
        text-align center
        line-height 1
        text-transform uppercase
        margin-bottom 5px
        @media(max-width 576px)
            font-size 24px
    &__subTitle
        text-align center
        font-size 16px
        color: #131414;
        text-transform uppercase
        font-weight bold
        line-height 1

.servicesGrid
    display: flex
    flex-wrap: wrap
    justify-content center
    max-width 570px
    margin-left -15px
    margin-right -15px
    &__col
        width 50%
        padding 0 15px
        margin-top 11px
        @media(max-width 576px)
            width 100%

// -------------------------------------
.solutions
    padding: 64px 0 50px
    &__title
        text-align center
        font-family: 'Proxima Nova Bl'
        text-transform uppercase
        font-size: 30px;
        color: #131414;
        line-height 1
        margin-bottom 1px
        @media(max-width 576px)
            font-size 24px
    &__subTitle
        text-align center
        text-transform uppercase
        font-size: 18px;
        color: #131414;
        margin-bottom 17px
        line-height 1

.solutionsCards
    display: flex
    @media(max-width 1330px)
        flex-wrap: wrap
        margin-left -0.5px
        margin-right -0.5px

.solutionsCard
    position relative
    width 25%
    height 500px
    padding 20px 10px
    transition: width .3s
    @media(max-width 1330px)
        width 50%
        min-height 435px
        height auto
        border 0.5px solid transparent
    @media(max-width 992px)
        min-height 330px
    @media(max-width 768px)
        width 100%
        min-height: 180px
        display: flex
        flex-direction: column
        align-items flex-start
    &:before
        content: ''
        position: absolute
        top 0
        left 0
        size(100%)
        background-color: #13406e;
        opacity 0.5
        z-index: -1
    &__img
        position: absolute
        top 0
        left 0
        size(100%)
        object-fit: cover
        z-index: -2
    &__title
        text-transform uppercase
        font-size: 20px;
        font-weight: 700;
        color: #f2f1ee;
    &__text
        font-size: 14px;
        color: #f2f1ee;
        @media(max-width 768px)
            margin-bottom 20px
    &__button
        margin-top 30px
        opacity 0
        @media(max-width 1330px)
            display: none
        @media(max-width 768px)
            margin-top auto
    &:hover
        width 35%
        @media(max-width 1330px)
            width: 50%
        .solutionsCard__button
            opacity 1
        @media(max-width 768px)
            width: 100%


// ---------------------------------------
.advantages
    padding 25px 0 53px
    &__title
        text-align center
        font-family: 'Proxima Nova Bl'
        text-transform uppercase
        font-size: 30px;
        color: #131414;
        line-height 1
        margin-bottom 3px
        @media(max-width 576px)
            font-size 24px
    &__subTitle
        text-align center
        text-transform uppercase
        font-size: 18px;
        color: #131414;
        margin-bottom 18px
        line-height 1
    &__inner
        display: flex
        @media(max-width 1330px)
            align-items flex-end
        @media(max-width 768px)
            flex-direction: column
    &__col
        width 100%
        display: flex
        flex-direction: column
        justify-content center
        align-items center
        @media(max-width 1330px)
            &:first-child
                display: none

.buttonWrapper
    display: none
    @media(max-width 1330px)
        padding-top 25px
        display: flex
        justify-content center

.advantagesBox
    padding 15px 0 0
    max-width 250px
    width 100%
    &__text
        font-size 16px
        color: #131414;
    &--left
        display: flex
        align-items center
        .advantagesIcon
            margin-right 15px
    &--mob
        display: none
        @media(max-width 1330px)
            display: block
    @media(max-width 768px)
        display: flex
        align-items center
        max-width 100%
        .advantagesIcon
            margin-right 15px

.advantagesCapture
    position relative
    size(508px)
    border 15px solid #fff
    padding 15px
    display: flex
    justify-content center
    align-items flex-end
    &__img
        position: absolute
        top 0
        left 0
        size(100%)
        object-fit: cover
        z-index -1
        @media(max-width 992px)
            object-position: center right
    @media(max-width 1330px)
        width: 100%
        height 670px
    @media(max-width 768px)
        height 500px
    .button
        @media(max-width 1330px)
            display: none
            
.advantagesIcon
    position: relative
    size(65px, 77px)
    padding: 12px
    display: flex
    flex-shrink: 0
    z-index 1
    @media(max-width 576px)
        size(50px, 60px)
        padding: 10px
    &__img
        margin auto
        size(100%)
        object-fit: contain
    &:before
        content: ''
        absolute-center()
        size(100%)
        background-image url(../img/general/hexogan.png)
        background-repeat no-repeat
        background-size contain

.advantagesQuestion
    cursor pointer
    position: absolute
    top: -15px;
    right: -20px;
    size(28px, 32px)
    display: flex
    // @media(min-width 1330px)
    //     pointer-events: none
    @media(max-width 576px)
        size(20px, 24px)
        top: -10px;
        right: -10px;
    &:before
        content: ''
        absolute-center()
        background-image url(../img/general/hexquestion.png)
        background-repeat no-repeat
        background-size contain
        size(28px, 32px)
        z-index -1
        @media(max-width 576px)
            size(20px, 24px)
    &__icon
        flex-shrink: 0
        size(20px)
        margin: auto
        color #fff
        border: 1px solid #fff
        border-radius: 50%
        display: flex
        justify-content center
        align-items center
        @media(max-width 576px)
            size(15px)
            font-size 10px
            border none
    &__text
        display: none
        width 190px
        border-radius: 10px;
        background-color: rgb(37, 106, 147);
        padding 10px
        position: absolute
        top calc(100% - 10px)
        left calc(100%)
        font-size 14px
        color #fff
       

    &:hover
        .advantagesQuestion__text
            display: block
            @media(max-width 1330px)
                display: none




// ---------------------------------------
.done
    padding 25px 0

.gallery
    display: flex
    flex-wrap: wrap
    margin-left -0.5px
    margin-right -0.5px
    &__item
        position relative
        width 33.33%
        height 286px
        display: flex
        justify-content center
        align-items: center
        border 0.5px solid transparent
        @media(max-width 1330px)
            width 50%
            height 30vw
        @media(max-width 768px)
            width 100%
            height 60vw
        &:before
            content: ''
            position: absolute
            top 0
            left 0
            size(100%)
            background-color: #13406e;
            opacity 0.5
            z-index -1
            transition: opacity .3s
        &:hover
            &:before
                opacity 0
            .gallery__button
                 transform: scale(1)
    &__button
        transform: scale(0)

.galleryText
    width 33.33%
    height 286px
    display: flex
    flex-direction: column
    justify-content center
    align-items center
    text-align center
    @media(max-width 1330px)
        width 50%
        height 30vw
    @media(max-width 768px)
        display: block
        height auto
        width 100%
        margin-bottom 25px
    &__smoll
        text-transform uppercase
        font-size: 30px;
        font-weight: 700;
        line-height 1
        @media(max-width 768px)
            font-family: 'Proxima Nova Bl'
            display: inline-block
            font-size 30px
    &__big
        font-family: 'Proxima Nova Bl'
        text-transform uppercase
        font-size: 48px;
        line-height 1
        @media(max-width 768px)
            display: inline-block
            font-size 30px

.galleryImg
    position: absolute
    top 0
    left 0
    size(100%)
    object-fit: cover
    z-index -2




// ----------------------------------
.chart
    padding 100px 0
    @media(max-width 992px)
        padding 50px 0
    @media(max-width 480px)
        padding: 0px 0 100px;

    

.chartBox
    size(480px)
    margin 0 auto
    position relative
    display: flex
    flex-direction: column
    justify-content center
    align-items center
    padding 25px
    @media(max-width 992px)
        transform: scale(0.8);
    @media(max-width 480px)
        size(290px)
        transform: scale(0.6);
    &__title
        font-family: 'Proxima Nova Bl'
        text-transform uppercase
        font-size 30px
        color: #131414;
        text-align center
        display: none
        @media(max-width 768px)
            display: block
        @media(max-width 576px)
            font-size 24px
    &__text
        font-size 18px
        color: #131414;
        text-align center
        display: none
        @media(max-width 768px)
            display: block
            margin-bottom 25px
    .chartItem
        position: absolute
        &__title
            font-size 16px
            font-weight bold
            color: #131414;
            @media(max-width 768px)
                font-size 20px
        &__text
            font-size 16px
            color: #131414;
            @media(max-width 768px)
                font-size 20px
        &__content
            position: absolute
            width 155px
            @media(max-width 768px)
                width 200px
        &--blue
            top 0
            left 0
            right 0
            display: flex
            justify-content center
            .chartItem__balun
                display: block
                size(276px, 89px)
            .chartItem__content
                top -50px
        &--yellow
            top -25px
            right 0
            height 100%
            display: flex
            align-items center
            @media(max-width 480px)
                top: 72px;
                right: -100px;
            .chartItem__balun
                display: block
                size(107px, 336px)
            .chartItem__content
                right -180px
                @media(max-width 768px)
                    right: 40px
        &--red
            left 0
            right -40px
            bottom -16px
            display: flex
            justify-content center
            @media(max-width 480px)
                right: -40px;
                bottom: -200px;
            .chartItem__balun
                display: block
                size(346px, 162px)
            .chartItem__content
                bottom -50px
        &--green
            top: 38px;
            left: -13px;
            transition: .3s
           
            .chartItem__content
                top 70px
                left -136px
                @media(max-width 768px)
                    left 95px
        &--main
            left: -13px;
            bottom: 34px;
            @media(max-width 480px)
                left: -110px;
                bottom: -154px;
            .chartItem__content
                top 70px
                left -136px
                @media(max-width 768px)
                    left 75px
        &--out
            top: 30px;
            left: -37px;
            &:before
                content: '';
                position: absolute;
                top: 86px;
                left: 119px;
                display: block;
                width: 2px;
                height: 100px;
                background-color: #333;
                -webkit-transform: rotate(-65deg);
                -ms-transform: rotate(-65deg);
                transform: rotate(-58deg);
                @media(max-width 768px)
                    display: none
            @media(max-width 480px)
                left -120px


.chartBox
    .chartBox__title
    .chartBox__text
        display block
        @media(max-width 768px)
            display: none!important

// ----------------------------------
.stages
    &__inner
        display: flex
        flex-direction: column
        align-items center
        background-color #f2efe6
        padding 110px 0
        position relative
    &__title
        font-family: 'Proxima Nova Bl'
        font-size: 30px;
        color: #131414;
        text-transform uppercase
        text-align center
        margin-bottom 150px
        @media(max-width 576px)
            font-size 24px

.steps
    display: flex
    flex-wrap: wrap
    justify-content center
    margin-bottom 100px
    &__item
        margin 0 50px
        &:last-child
            &:after
                display: none
        @media(max-width 1330px)
            margin: 0 25px
            width 170px

.stepsItem
    position relative
    height 150px
    display: flex
    z-index 1
    &__number
        absolute-center()
        font-family: 'Proxima Nova Bl'
        font-size: 150px;
        color: #d5d4d2;
        line-height 1
        z-index -1
    &__text
        margin auto
        text-align center
        font-size: 16px;
        color: #131414;
    &:after
        content: ''
        position: absolute
        top calc(50% - 1px)
        right -65px
        display: block
        width 40px
        height 2px
        background-color #135e8c
        @media(max-width 1330px)
            display: none





// -----------------------------------------------
.benefits
    padding 25px 0 31px
    
    &__inner
        position relative
        padding 71px 169px 71px 78px
        @media(max-width 1330px)
            padding: 70px 30px
        @media(max-width 768px)
            padding 50px 10px
    &__img
        position: absolute
        top 0
        left: 0
        size(100%)
        object-fit: cover
        z-index -1

.benefitContent
    border: 20px solid #ffffff;
    @media(max-width 768px)
        border: 10px solid #ffffff;
    &__wrapper
        display: inline-flex
        flex-direction: column
        background-color rgba(255, 255, 255, 0.8)
        padding 17px 19px 1px
        max-width: 710px;
        width: 100%;
        @media(max-width 1330px)
            max-width 570px
        @media(max-width 576px)
            padding 20px 10px
    &__title
        font-family: 'Proxima Nova Bl';
        font-size: 30px;
        font-weight bold
        text-align center
        line-height 1
        text-transform uppercase
        margin-bottom 5px
        @media(max-width 576px)
            font-size 24px
    &__subTitle
        text-align center
        font-size 16px
        color: #131414;
        text-transform uppercase
        font-weight bold
        line-height 1
        margin-bottom 33px

.benefitsGrid
    display: flex
    flex-wrap: wrap
    margin-left -15px
    margin-right -15px
    &__col
        width 50%
        padding 0 15px
        @media(max-width 768px)
            width: 100%

.advantagesItem
    display: flex
    align-items center
    margin-bottom 18px
    &__number
        flex-shrink: 0
        position relative
        size(45px, 52px)
        display: flex
        justify-content center
        align-items center
        font-weight 600
        font-size: 30px;
        color: #256a93;
        margin-right 15px
        &:before
            content: ''
            absolute-center()
            size(45px, 52px)
            background-image url(../img/general/hexogan.png)
            background-size contain
            background-repeat no-repeat

    &__title
        font-size 14px
        text-transform uppercase
        color: #131414;
        font-weight 600
        
    &__text
        font-size 14px
        color: #131414;
        font-weight 400



// ------------------------------------------
.totals
    padding 25px 0
    &__inner
        position relative
        padding 86px 0 42px
    &__img
        position absolute
        top 0
        left 0
        size(100%)
        object-fit: cover
        z-index -1
    &__title
        font-family: 'Proxima Nova Bl'
        font-size: 30px;
        color: #131414;
        text-transform uppercase
        text-align center
        @media(max-width 576px)
            font-size 24px

.totalsGrid
    display: flex
    flex-wrap: wrap
    margin-left -15px
    margin-right -15px
    &__col
        padding 0 15px
        width 50%
        display: flex
        flex-direction: column
        align-items center
        @media(max-width 992px)
            width 100%

.totalsBox
    max-width 370px
    width: 100%
    margin-top 40px
    &__icon
        size(48px)
        object-fit: contain
        margin-bottom 10px
    &__title
        font-family: 'Proxima Nova Bl';
        text-transform uppercase
        font-size 18px
        color: #131414;
    &__text
        font-size 16px
        color: #131414;
    @media(max-width 992px)
        padding 0 10px

.totalForm
    max-width 370px
    width: 100%
    margin-top 40px
    @media(max-width 992px)
        padding 0 15px
    &__input
        border none
        border-bottom: 1px solid #000
        height 36px
        width 100%
        text-align center
        font-size 14px
        color #000
        padding-left 15px
        padding-right 15px
        background-color transparent
        margin-bottom 20px
    &__button
        width: 100%
        margin-bottom 20px

.totalWarning 
    width 100%
    font-size 18px
    text-align center
    @media(max-width 576px)
        font-size 16px

    b
        font-size 24px
        // font-family: 'Proxima Nova Bl';
        @media(max-width 576px)
            font-size 18px
    &--mb11
        margin-bottom 11px
    &--mb24
        margin-bottom 24px

.totalSale
    width 100%
    text-align center
    font-size 16px
    color: #252525;
    @media(max-width 576px)
        font-size 14px
    &--mt20
        margin-top 20px