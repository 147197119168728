.button
    position relative
    overflow hidden
    cursor pointer
    border-radius: 10px
    border: 1px solid transparent
    padding: 13.5px 25px
    font-size 16px
    color #fff
    transition: .3s
    text-align center
    &__elips
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.4);
        border-radius: 100%;

        top: 100%;
        left: 0;
        right 0
        margin 0 auto


        width: calc(100%);
        height: calc(100px);
        z-index: 0;
    &--blue
        border-color: #125c89;
        background-color: #143665;
        background-image: linear-gradient(to top, #125c89 0%, #1a70a5 100%);
        &:hover
            box-shadow: 0px 0px 20px 0px rgba(27,119,175,1);
    &--red
        border-color #ca1e20;
        background-color: #b6b6b6;
        background-image: linear-gradient(to top, #ca1e20 0%, #e80914 100%);
        &:hover
            box-shadow: 0px 0px 20px 0px rgba(202,30,32,1);
    &--smoll
        padding: 7px 20px
        font-size 14px

.animate {
  -webkit-animation: ripple 1.65s linear;
  animation: ripple 1.65s linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
  }
}
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
  }
}
@keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
  }
}
        

.zoomButton
    cursor pointer
    padding: 9px 19px
    border-radius: 10px;
    border: 1px solid #125d8b;
    background-color: #135e8c;
    background-image: linear-gradient(to top, #125d8a 0%, #196fa3 100%);
    transition: .3s
    &__icon
        size(30px)

.hamburgerButton
    display none
    cursor pointer
    border none
    padding 40px 30px 20px
    background-color #b6b6b6
    &__img
        size(29px)
        object-fit: contain
        margin auto
        &--main
            display: block
        &--home
            display: none
    &.active
        .hamburgerButton__img
            &--main
                display: none
            &--home
                display: block
    @media(max-width 1481px)
        display: flex
    @media(max-width 768px)
        padding: 20px 19px
    @media(max-width 479px)
        padding 20px 10px