.modal
    display: none
    max-width 500px!important
    width 100%
    min-height 450px
    background-color #fff
    padding 74px 85px 25px!important
    @media(max-width 768px)
        padding 25px!important
        min-height: auto
    &__title
        font-family: 'Proxima Nova Bl'
        text-transform uppercase
        text-align center
        font-size 18px
        color #252525
        &--mb50
            margin-bottom 50px
        &--mb10
            margin-bottom 10px

.modalHint
    background-color #256a93!important
    padding: 70px!important
    
    @media(max-width 768px)
        padding 25px!important
        min-height: auto
    &__text
        font-size 20px
        color #ffffff
        font-weight 300
        text-align center

        width 100%
        min-height 360px
        display: flex
        justify-content center
        align-items center



.modalForm
    display: flex
    flex-direction: column
    &__button
        width: 100%
        margin-top 47px

.formInput
    border none
    border-bottom: 1px solid #000
    height 36px
    width 100%
    text-align center
    font-size 14px
    color #000
    padding-left 15px
    padding-right 15px
    background-color transparent
    margin-bottom 20px


.fancybox-button.fancybox-close-small
    svg
        display: none
    &:before
        content: ''
        display: block
        size(30px)
        background-image url(../img/general/close.png)
        background-size contain
        background-repeat no-repeat
        background-position center
        absolute-center()

.modalHint .fancybox-button.fancybox-close-small
    svg
        display: none
    &:before
        content: ''
        display: block
        size(30px)
        background-image url(../img/general/closeWhite.png)
        background-size contain
        background-repeat no-repeat
        background-position center
        absolute-center()

.fancyGallery 
    .fancybox-content
        border 20px solid #fff
    .fancybox-button.fancybox-button--close
        background-color #fff
        svg
            display: none
        &:before
            content: ''
            display: block
            size(30px)
            background-image url(../img/general/close.png)
            background-size contain
            background-repeat no-repeat
            background-position center
            absolute-center()
    .fancybox-button.fancybox-button--arrow_left
        opacity 0.7
        transition: .3s
        cursor pointer
        background-color transparent
        svg
            display: none
        &:before
            content: ''
            display: block
            size(50px)
            background-image url(../img/general/left.png)
            background-size contain
            background-repeat no-repeat
            background-position center
            absolute-center()
        &:hover
            opacity 1
    .fancybox-button.fancybox-button--arrow_right
        opacity 0.7
        transition: .3s
        cursor pointer
        background-color transparent
        svg
            display: none
        &:before
            content: ''
            display: block
            size(50px)
            background-image url(../img/general/right.png)
            background-size contain
            background-repeat no-repeat
            background-position center
            absolute-center()
        &:hover
            opacity 1