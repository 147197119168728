.header
    background-color #f2f1ef
    position: fixed
    top 0
    left 0
    width 100%
    z-index 2
    transition: .3s
    &__inner
        display flex
        align-items flex-end
        padding 0 116px
        @media(max-width 1481px)
            padding 0
         @media(max-width 768px)
             align-items center
    &__logo
        margin-right 43px
        margin-bottom 10px
        @media(max-width 768px)
            margin-right 20px
            margin-bottom 0
    &__phone
        margin-left: auto
        margin-bottom 10px
        @media(max-width 768px)
            margin-bottom 0
    &.active
        box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);

.logo
    flex-shrink: 0
    size(90px, 65px)
    @media(max-width 768px)
        size(65px, 45px)
    &__icon
        size(100%)
        object-fit: contain

.navigation
    @media(max-width 1450px)
        display: none
        position: absolute
        top 100%
        left 0
        width 100%
        background-color #b6b6b6
        padding: 10px 0
        box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
    &__link
        display: inline-block
        padding 54px 10px 21px
        font-size 14px
        line-height 1
        color #252525;
        transition: background-color .3s, color .3s
        &:hover
            background-color: #b6b6b6;
            color: #fff
        @media(max-width 1481px)
            display: block
            height auto
            padding 15px 0
            text-align center
            &:hover
                background-color #fff
                color #252525;
            
.phone
    display flex
    flex-direction: column
    align-items flex-end
    &__link
        text-align right
        font-size 18px
        line-height 1
        color #000
        @media(max-width 576px)
            font-size 14px
    &__button
        margin-top 8px
        @media(max-width 576px)
            padding 5px 10px!important
            font-size 12px!important
    

