@import 'utils/fonts.styl'
@import 'utils/vars.styl'
@import 'utils/mixins.styl'
@import 'libs.styl'

html
	font-size baseFontSize
	height 100%
	line-height 1.3

body
	font-family mainFont
	height 100%
	background-color #eee

h1,h2,h3,h4,h5,h6
	margin 0

ul,li
	list-style-type none
	margin 0
	padding 0

p
	margin 0

a
	color black
	text-decoration none

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle

textarea
	resize none

section
	position relative

input,
select,
button
	outline none

*, *:before, *:after
	box-sizing border-box

.wrapper
	display flex
	flex-direction column
	height 100%

.content
	padding-top 89px
	flex 1 0 auto
	@media(max-width 768px)
		padding-top: 65.36px

.container
	width 1350px
	margin 0 auto
	padding: 0 15px
	@media(max-width 1481px)
		width 1200px
	@media(max-width 1330px)
		width 900px
	@media(max-width 992px)
		width 690px
	@media(max-width 768px)
		max-width 690px
		width 100%

// section 
// header
// footer
// 	overflow hidden
//Модули
@import "modules/modules.styl"

// СТРАНИЦЫ
// Главная страница
@import 'pages/index/index.styl'