footer
	flex 0 0 auto

.footer
	&__inner
		display flex
		padding 35px 0
		@media(max-width 992px)
			flex-direction: column-reverse


.contacts
	max-width: 583px;
	width 100%
	min-height: 576px;
	padding 110px 25px
	background-color: #135e8c;
	@media(max-width 992px)
		max-width 100%
		padding 50px 25px
		min-height auto
	&__wrapper
		max-width 320px
		width 100%
		margin 0 auto
	&__title
		font-family: 'Proxima Nova Bl'
		text-transform uppercase
		font-size: 36px;
		color: #fffefc;
		margin-bottom 50px

.contactBox
	margin-bottom 25px
	&__title
		font-size 18px
		text-transform uppercase
		color #fff
	&__link
		font-size 18px
		color #fff

.social
	display: flex
	flex-wrap: wrap
	align-items center
	&__link
		margin-right 15px
		size(26px)
		opacity 0.7
		transition: opacity .3s
		img
			size(100%)
			object-fit: contain
		&:last-child
			margin-right 0
		&:hover
			opacity 1

#map
	width 100%
	height: 576px;
	background-color #bbb
	@media(max-width 992px)
		height 300px